import React, { Fragment, memo, useMemo } from "react";
import Img from "gatsby-image";
import cn from "classnames";
import * as styleConstants from "@/constants/style";
import {
  HOME_PAGE_BEFORE_SEASON_MODE,
  HOME_PAGE_OFFSEASON_MODE,
  HOME_PAGE_SEASON_MODE,
} from "@/constants";
import { useRoute } from "@/utils";
import Button from "@/components/kit/button";
import ShowPreview from "@/components/show-preview";
import Carousel from "@/components/carousel";
import SubscriptionForm from "@/components/subscription-form";
import ArtDirectorButton from "@/components/art-director-button";
import GlobalNav, { AniLink } from "@/components/global-navigation";
import { useStateValue } from "@/store";
import AniLogo from "@/components/kit/ani-logo";
import * as css from "@/components/pages/index/index.modules.scss";
import { useI18n } from "@/plugins/i18n";
import { graphql } from "gatsby";
import { ClientOnly } from "@khokhlachev/react";

const CAROUSEL_ASPECT_RATIO = 1344 / 747;

const replaceSpecialInclude = (string: string): string | undefined => {
  if (string) {
    return string.replace(/\|(\w+)\|/, '<span class="title__e">$1</span>');
  }
};

const IndexPage = ({ data, pageMode, navLinks }) => {
  const { layoutHome, siteSettings } = data;
  const route = useRoute();
  const [{ locale }] = useStateValue();

  const {
    subscriptionPlaceholderText,
    subscriptionPlaceholderTextMobile,
    subscriptionLeadText,
    subscriptionCtaText,
  } = siteSettings;

  const mode = pageMode || layoutHome.pageMode;

  const isSeason = mode === HOME_PAGE_SEASON_MODE;
  const isBeforeSeason = mode === HOME_PAGE_BEFORE_SEASON_MODE;
  const isOffSeason = mode === HOME_PAGE_OFFSEASON_MODE;

  const { t } = useI18n();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={
        !isOffSeason && "desktop:-mt-10 desktop1280:-mt-12 desktop1440:-mt-14"
      }
    >
      {!isOffSeason &&
        Array.isArray(layoutHome.keyVisualRefs) &&
        layoutHome.keyVisualRefs.length > 0 && (
          <ClientOnly placeholder={<RandomImagePlaceholder />}>
            <RandomImage images={layoutHome.keyVisualRefs} />
          </ClientOnly>
        )}

      <div className="relative">
        <div className={cn(styleConstants.container, "z-10 sticky top-0")}>
          <GlobalNav
            links={navLinks}
            className="py-3 desktop:py-4 desktop1280:py-5 text-title-1 bg-white "
          />
        </div>

        <section className={cn(styleConstants.container)}>
          <header className={"pb-8 desktop:pb-10 desktop1280:pb-12"}>
            <h1
              className={cn(
                "pt-7 pb-5 desktop:pt-5 desktop1280:pt-6 desktop1440:pt-10 tablet:pb-0 border-b-2 tablet:border-0",
                css.title
              )}
              dangerouslySetInnerHTML={{
                __html: replaceSpecialInclude(
                  layoutHome.lead_text?.lead_text?.replace(/\n/g, "<br>")
                ),
              }}
            />

            <div
              className={
                "flex mt-6 tablet:mt-7 desktop:mt-8 desktop1280:mt-10 desktop1440:mt-9 " +
                "justify-between relative"
              }
            >
              <div className={"flex flex-col tablet:flex-row desktop:w-10/12"}>
                {Array.isArray(layoutHome.partners) &&
                  layoutHome.partners.map((item, i) => {
                    return (
                      <Fragment key={`fragment${i}`}>
                        <div
                          className={cn({ "mt-10 tablet:mt-0": i > 0 })}
                          key={i}
                        >
                          {item.title && (
                            <div className={"mt-3 mb-3 desktop1280:mb-4"}>
                              {item.title}
                            </div>
                          )}
                          <div className="flex flex-wrap gap-y-4 desktop:gap-y-9">
                            {item.images?.map((img, ind) => (
                              <div
                                key={ind}
                                className={css.partnerImageContainer}
                              >
                                <Img
                                  {...img}
                                  className={css.partnerImage}
                                  loading={"eager"}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        {i !== layoutHome.partners.length - 1 ? (
                          <div className={"w-2/24"} key={`div-${i}`} />
                        ) : null}
                      </Fragment>
                    );
                  })}
              </div>
              <div className={"hidden tablet:block absolute right-0"}>
                <a
                  className={css.languageLink}
                  href={locale === "ru" ? "/en/" : "/"}
                >
                  {locale === "ru"
                    ? t("localeTitles.en")
                    : t("localeTitles.ru")}
                </a>
              </div>
            </div>
          </header>
        </section>

        <section className={styleConstants.container}>
          <div
            className={`
            flex flex-col tablet:flex-row desktop1440:border-b-3 border-b-2 desktop1440:border-t-3 border-t-2
            py-7 tablet:py-4 desktop:pt-5 desktop:pb-6 desktop1280:pb-10 desktop1440:pt-7
            `}
          >
            <div className={"hidden desktop:block w-3/24"}>
              <AniLogo className={"w-full h-auto"} />
            </div>
            <div className={"hidden desktop:block w-2/24"}></div>
            <div
              className={cn(
                "w-full tablet:w-16/24 desktop:w-13/24 desktop1280:w-12/24 text-title-1 uppercase",
                "flex desktop:block items-center"
              )}
              dangerouslySetInnerHTML={{
                __html: layoutHome.art_dir_text?.childMarkdownRemark?.html,
              }}
            />
            {Array.isArray(layoutHome.artDirectors) && (
              <div
                className={"flex-1 flex tablet:justify-end mt-5 tablet:mt-0"}
              >
                {layoutHome.artDirectors.map((item, i) => (
                  <ArtDirectorButton key={i} {...item} />
                ))}
              </div>
            )}
          </div>
        </section>

        <section className={styleConstants.container}>
          {isOffSeason && Array.isArray(layoutHome?.offseasonCarousel) && (
            <Carousel
              className={
                "my-7 tablet:my-6 desktop:my-11 desktop1280:my-14 desktop1440:my-16"
              }
            >
              {layoutHome.offseasonCarousel.map(({ fluid }, i) => (
                <Img
                  key={i}
                  fluid={{ ...fluid, aspectRatio: CAROUSEL_ASPECT_RATIO }}
                />
              ))}
            </Carousel>
          )}
        </section>

        {!isSeason && (
          <div
            className={
              "my-12 tablet:my-16 desktop:my-22 desktop1280:my-28 desktop1440:my-32"
            }
          >
            <SubscriptionForm
              leadText={subscriptionLeadText}
              placeholderText={subscriptionPlaceholderText}
              placeholderTextMobile={subscriptionPlaceholderTextMobile}
              buttonText={subscriptionCtaText}
              className={styleConstants.container}
            />
          </div>
        )}

        {(isSeason || isBeforeSeason) && Array.isArray(layoutHome.shows) && (
          <section className={`${styleConstants.container}`}>
            <header
              className={cn("text-h2 pt-4 mb-9 tablet:mb-10 tablet:mb-0", {
                "desktop1440:border-t-3 border-t-2": isBeforeSeason,
              })}
            >
              <span className={css.programTitle}>
                {layoutHome.program_title}
              </span>
            </header>
            {layoutHome.shows.map((item, i) => (
              <ShowPreview
                key={item.id}
                author={item.author}
                title={item.title}
                slug={item.slug}
                cover={item?.cover_listing_image?.fluid}
                description={item?.previewDescription?.previewDescription}
                align={i % 2 === 0 ? "left" : "right"}
                atomicTickets={isBeforeSeason && item.atomicTickets}
                canBuy={isSeason}
                showCityShortCode={item.ticketsJson?.tickets?.length}
                className={`
              mt-5 tablet:mt-9 desktop:mt-7 desktop1280:mt-9 desktop1440:mt-11
              pb-9 tablet:pb-0 border-b-2 tablet:border-0
              `}
              />
            ))}
            <Button
              is={AniLink}
              to={route("program")}
              className={"w-full mt-9"}
            >
              {layoutHome.programLinkText}
            </Button>
          </section>
        )}
      </div>

      <div
        className={cn(
          "mb-18 desktop:mb-22 desktop1280:mb-28 desktop1440:mb-30 desktop1440:pt-2"
        )}
      />
    </div>
  );
};

export default IndexPage;

const RandomImage = memo(function RandomImage({ images }: { images: any[] }) {
  const { large, small } = useMemo(
    () => images[Math.round(Math.random() * (images.length - 1))],
    [images]
  );

  return (
    <div className="relative w-full h-0 pb-[120%] tablet:pb-[50%]">
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden flex items-center justify-center">
        {small && (
          <Img
            fluid={small.fluid}
            className="tablet:hidden w-full h-full object-cover"
          />
        )}
        {large && (
          <Img
            fluid={large.fluid}
            className={
              "w-full h-full object-cover " +
              (small ? "hidden tablet:block" : "")
            }
          />
        )}
      </div>
    </div>
  );
});

const RandomImagePlaceholder = memo(function RandomImagePlaceholder() {
  return <div className="h-0 pb-[50%]"></div>;
});

export const homePageKeyVisualsFragment = graphql`
  fragment HomePageKeyVisuals on ContentfulLayoutHome {
    keyVisualRefs {
      large {
        fluid(maxWidth: 2000, toFormat: JPG, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      small {
        fluid(
          maxWidth: 415
          maxHeight: 800
          toFormat: JPG
          quality: 80
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
