import React from "react";
import qs from "qs";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Field } from "formik";
import cn from "classnames";
import axios from "axios";
import Button from "@/components/kit/button";
import useMedia from "@/utils/useMedia";
import { useIntersection } from "react-use";
import * as css from "./subscriptionForm.modules.scss";

const initialValues = { email: "" };
const initialState = { isLoading: false, isSuccess: false, isError: false };
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET":
      return initialState;
    case "REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "SUCCESS":
      return {
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    case "ERROR":
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
  }
};

const isValidForm = (errors, touched) => {
  return !Object.keys(errors).some((key) => {
    return errors[key] && touched[key];
  });
};

type SubscriptionFormProps = {
  className?: string;
  leadText: string;
  placeholderText: string;
  placeholderTextMobile: string;
  buttonText: string;
};

const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  className,
  leadText,
  placeholderText,
  placeholderTextMobile,
  buttonText,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [intersectOnce, setIntersectOnce] = React.useState(false);
  const recapRef = React.useRef(null);
  const rootRef = React.useRef(null);
  const intersection = useIntersection(rootRef, {
    root: null,
    rootMargin: "300px 0px",
  });
  const placeholder = useMedia(
    ["(min-width: 1024px)"],
    [placeholderText],
    placeholderTextMobile
  );

  const FORM_FIELDS: Array<{
    name: string;
    placeholder?: string;
    required?: boolean;
  }> = [
    {
      name: "email",
      placeholder: placeholder,
      required: true,
    },
  ];
  const ERROR_MESSAGES: { [key: string]: string } = {
    required: "Это обязательное поле",
    email: "Некорректный email",
  };

  React.useEffect(() => {
    if (intersection?.isIntersecting) {
      setIntersectOnce(true);
    }
  }, [intersection?.isIntersecting]);

  const verifyCaptcha = (): Promise<string> =>
    new Promise((resolve, reject) => {
      if (recapRef?.current) {
        recapRef.current.reset();
        recapRef.current.executeAsync().then(resolve).catch(reject);
      } else {
        reject("recaptcha is not initialized");
      }
    });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch({ type: "REQUEST" });
    setSubmitting(true);

    verifyCaptcha()
      .then((token) => {
        const requestBody = FORM_FIELDS.reduce(
          (acc, field) => {
            acc[field.name] = values[field.name];
            return acc;
          },
          { "g-recaptcha-token": token }
        );

        axios({
          method: "POST",
          url: process.env.SUBSCRIPTION_API_URL,
          data: qs.stringify(requestBody),
          headers: { "content-type": "application/x-www-form-urlencoded" },
        })
          .then(() => {
            dispatch({ type: "SUCCESS" });

            setSubmitting(false);

            setTimeout(function () {
              dispatch({ type: "RESET" });
              resetForm(initialValues);
            }, 3000);
          })
          .catch((err) => {
            console.error(err);

            dispatch({ type: "ERROR" });
            setSubmitting(false);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleValidate = (values) => {
    const errors: { [key: string]: string } = {};

    for (let field of FORM_FIELDS.filter(
      (x) => !!x.required && x.name !== "email"
    )) {
      if (!values[field.name]) {
        errors[field.name] = ERROR_MESSAGES.required;
      }
    }

    if (!values.email) {
      errors.email = ERROR_MESSAGES.required;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = ERROR_MESSAGES.email;
    }

    return errors;
  };

  return (
    <div ref={rootRef} className={className}>
      <div
        className={"pb-8 text-h1"}
        dangerouslySetInnerHTML={{ __html: leadText }}
      />

      <Formik
        initialValues={initialValues}
        validate={handleValidate}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className={"text-body-2 flex flex-wrap items-end w-full"}
          >
            {intersectOnce && (
              <div className={"hidden"}>
                <ReCAPTCHA
                  ref={recapRef}
                  sitekey={process.env.RECAPTCHA_API_KEY}
                  size={"invisible"}
                />
              </div>
            )}

            {FORM_FIELDS.map((fieldBag) => {
              return (
                <div key={fieldBag.name} className={"relative flex-1"}>
                  <Field
                    type="text"
                    name={fieldBag.name}
                    placeholder={fieldBag.placeholder}
                    value={values[fieldBag.name]}
                    className={cn(css.textInput, {
                      [css.textInputContainsError]:
                        errors[fieldBag.name] && touched[fieldBag.name],
                    })}
                  />
                </div>
              );
            })}

            <Button
              loading={state.isLoading}
              type={"submit"}
              noHover
              className={cn(
                css.button,
                "w-full tablet:w-1/4 mt-2 tablet:mt-0",
                {
                  "text-red border-red": !isValidForm(errors, touched),
                }
              )}
            >
              {state.isSuccess
                ? "Готово"
                : state.isError
                ? "Ошибка"
                : buttonText}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SubscriptionForm;
