import React from "react";
import { useRoute } from "@/utils";
import cn from "classnames";
import Img, { FluidObject } from "gatsby-image";
import Button from "@/components/kit/button";
import { AtomicTicket } from "@/components/pages/program/types";
import TicketBubbleGroup from "../ticket-bubble/group";
import { AniLink } from "../global-navigation";
import { useI18n } from "@/plugins/i18n";
import isEqual from "react-fast-compare";

interface ShowPreviewProps {
  author: string;
  title: string;
  slug: string;
  description?: string;
  cover?: FluidObject;
  align?: "left" | "right";
  className?: string;
  atomicTickets: AtomicTicket[];
  showCityShortCode: boolean;
  canBuy: boolean;
}

const ShowPreview: React.FC<ShowPreviewProps> = React.memo(
  function ShowPreview({
    title,
    author,
    slug,
    description,
    cover,
    align = "left",
    className,
    atomicTickets,
    showCityShortCode,
    canBuy,
  }) {
    const route = useRoute();
    const i18n = useI18n();

    return (
      <article
        className={cn(
          "flex flex-col",
          { "tablet:flex-row": align === "left" },
          { "tablet:flex-row-reverse": align === "right" },
          className
        )}
      >
        <aside className={"tablet:w-7/12"}>
          {cover && (
            <AniLink
              to={route("home", slug)}
              className={"block hover:no-underline"}
            >
              <Img fluid={cover} alt={title} />
            </AniLink>
          )}
        </aside>
        <div className={"hidden tablet:block w-1/24"}></div>
        <main className={"tablet:w-3/8 mt-6 tablet:mt-0"}>
          <header className={"text-h3 uppercase"}>
            <AniLink to={route("home", slug)}>
              {author && (
                <span className={"font-extrabold"}>{author} &mdash; </span>
              )}
              {title}
            </AniLink>
          </header>
          {description && (
            <div className={"mt-3 tablet:mt-4"}>{description}</div>
          )}
          <Button
            is={AniLink}
            to={route("home", slug)}
            className={
              "w-full desktop:w-2/3 mt-6 tablet:mt-5 desktop:mt-6 desktop1280:mt-7"
            }
          >
            {canBuy ? i18n.t("tickets") : i18n.t("schedule")}
          </Button>
          {Array.isArray(atomicTickets) && (
            <TicketBubbleGroup
              tickets={atomicTickets}
              maxCount={3}
              className={"w-full desktop:w-2/3 mt-2 desktop1280:mt-3"}
              classNameTicketBubble={"w-1/3"}
              showCityShortCode={showCityShortCode}
              slug={slug}
              canBuy={canBuy}
            />
          )}
        </main>
      </article>
    );
  },
  isEqual
);

export default ShowPreview;
